import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Euforia = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #794B9B, #8248B1, #9452C8, #8248B1, #794B9B )",
        icons: "#A26DCD",
        navClass: "euforia",
      }}
      seo={{
        title: "Perfumeria Euforia",
        headerTitle: "euforia",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/euforia_top_back.png"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/euforia/",
      }}
    >
      <PortfolioHeader name="euforia" height="88" />
      <section className="container-fluid euforia_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Perfumeria Euforia</h1>
              <ul>
                <li>Kampania produktowa</li>
                <li>Case Study</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Perfumeria Euforia to znany polski sklep internetowy oferujący
                między innymi oryginalne perfumy i kosmetyki. Marka obecna jest
                na rynku od 2009 r. i od tego czasu z powodzeniem prowadzi
                sprzedaż artykułów drogeryjnych, z roku na rok rozszerzając swój
                asortyment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_3">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4>Problem</h4>
            <p>
              Jak w przypadku wszystkich biznesów e-commerce głównym celem
              kampanii jest zwiększenie sprzedaży – i właśnie z takim problemem
              zgłosił się do nas Klient w kwietniu 2017 r. Prowadzona przez inną
              agencję kampania Google Ads generowała transakcje, jednak nie były
              one dla Klienta zadowalające – perfumerie internetowe są bardzo
              popularne, a oferowane produkty znane są docelowym klientom,
              dlatego szukają sklepu, który zaoferuje produkt po najniższej
              cenie. Stąd problemem jest niska marża nałożona na te produkty, a
              zatem znacznie niższy zwrot z inwestycji niż w przypadku innych
              branż. Rozwiązaniem tej sytuacji powinien być więc jak najwyższy
              przychód z kampanii przy jak najniższym jej koszcie.
            </p>
            <h4>Rozwiązanie</h4>
            <p>
              Podczas tworzenia kampanii skupiliśmy się przede wszystkim na
              kampaniach produktowych, które umożliwiają wyświetlanie się zdjęć
              i krótkich opisów produktów w wyszukiwarce Google i najlepiej
              sprawdzają się do uzyskania wysokich sprzedaży przy małym
              nakładzie finansowym. Kampanie produktowe wspierane są także
              remarketingiem dynamicznym (ponowne wyświetlanie produktów,
              którymi użytkownik był wcześniej zainteresowany oraz umożliwienie
              dokończenia procesu zakupowego) oraz kampanią w sieci wyszukiwania
              na frazy brandowe.
            </p>
            <p>
              Kampanie produktowe od samego początku wykazywały zdecydowaną
              skuteczność, jednak wymagały stałej obserwacji i optymalizacji. W
              lipcu 2018 r. podjęliśmy decyzję o skorzystaniu z nowości Google
              (bety) dostępnej dla kampanii spełniających określone warunki,
              czyli z inteligentnej kampanii produktowej. Jest to mocno
              zautomatyzowany podtyp kampanii, łączący kampanię produktową i
              remarketingową w jedną. Pomimo małych utrudnień na początku
              testów, okazało się, że kampania inteligentna była strzałem w
              dziesiątkę – z dnia na dzień przynosiła coraz lepsze efekty.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_4">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <div className="top">
              <h4>Efekty</h4>
              <p>
                Porównanie dwóch analogicznych miesięcznych okresów kampanii:{" "}
                <br />
                działań poprzedniej agencji i działań naszej agencji:
              </p>
              <img
                src={require("../../assets/img/portfolio/euforiatop.png")}
                alt=""
              />
            </div>
            <div className="row bottom">
              <TrackVisibility
                once
                offset={300}
                className="col-md-6 list__container"
              >
                {({ isVisible }) => (
                  <ul className="list">
                    <li>
                      <span>
                        Wzrost przychodów o{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 4318 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Wzrost liczby transakcji o{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 4075 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Wzrost współczynnika konwersji z 1% do{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 5.2 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Wzrost ROAS z{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 213 : 0}
                        />
                        % do{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 3358 : 0}
                        />
                        %
                      </span>
                    </li>
                    <li>
                      <span>
                        Spadek kosztów uzyskania przychodów z{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 47 : 0}
                        />
                        % do{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 3 : 0}
                        />
                        %
                      </span>
                    </li>
                  </ul>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-md-6">
                {({ isVisible }) => (
                  <>
                    <p>
                      W omawianym okresie nasza kampania była również głównym
                      źródłem ruchu i przychodów w witrynie. Wszystkich
                      konwersji, do których przyczyniła się kampania Google Ads
                      było ponad{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 3000 : 0}
                      />{" "}
                      a ich wartość wyniosła prawie{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 500000 : 0}
                      />{" "}
                      zł.
                    </p>
                    <p>
                      Działania dla tego Klienta pozwoliły nam na otwarcie się
                      na nowy typ reklamy Allegro Ads oraz na przetestowanie
                      funkcjonowania inteligentnych kampanii Google Ads. Dzięki
                      temu potwierdziliśmy ich skuteczność i sukcesywnie
                      przekształcamy na inteligentne inne prowadzone przez nas
                      kampanie e-commerce.
                    </p>
                  </>
                )}
              </TrackVisibility>
            </div>
            <div className="row justify-content-center">
              <div className="col">
                <img
                  src={require("../../assets/img/portfolio/euforia1.png")}
                  alt=""
                />
                <span className="photo-info">
                  Wyniki poprzedniej kampanii Google Ads. Źródło: Google
                  Analytics.
                </span>
                <img
                  src={require("../../assets/img/portfolio/euforia2.png")}
                  alt=""
                />
                <span className="photo-info">
                  Wyniki kampanii Google Ads prowadzonej przez agencję When.
                  Źródło: Google Analytics.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid euforia_section_5">
        <div className="row justify-content-center">
          <TrackVisibility once offset={300} className="col-md-8">
            {({ isVisible }) => (
              <>
                <h4>Bonus</h4>
                <p>
                  Dla Perfumerii Euforii w okresie przedświątecznym
                  prowadziliśmy dodatkowo kampanię Allegro Ads. Jest to ciekawe
                  wsparcie kampanii Google Ads dla sklepów, które sprzedają swój
                  asortyment także przez Allegro. Kampania Allegro Ads trwała{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 15 : 0} />{" "}
                  dni i przy testowym budżecie w wysokości{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 514 : 0} />{" "}
                  zł uzyskaliśmy{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 158 : 0} />{" "}
                  sprzedanych sztuk o wartości{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 16602 : 0} />{" "}
                  zł. Należy jednak pamiętać, że konwersje w tym narzędziu
                  naliczane są w inny sposób niż w Google Analytics (gdzie brane
                  są pod uwagę przede wszystkim transakcje z ostatniego
                  kliknięcia), a sprzedane sztuki to łączna liczba sprzedanych
                  ofert zliczona przez{" "}
                  <CountUp start={0} duration={3} end={isVisible ? 7 : 0} />{" "}
                  kolejnych dni od kliknięcia w reklamę Allegro Ads. Dlatego nie
                  warto zestawiać tego z wynikami kampanii Google Ads.
                </p>
                <p>
                  Przedstawione case study dotyczy projektu realizowanego przez
                  agencję When - część Grupy ADream
                </p>
              </>
            )}
          </TrackVisibility>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Euforia;
